<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Convey the core messaging of your brand<br />
          with more clarity to your target audience.
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source
              src="https://cornerstone.40parables.com/wp-content/uploads/2021/07/Problem-Priority-CornerStone-Prework.mp4"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>

      <div class="main-frame">

        <div class="golden-pyramid-container">
          <div class="question-heading">Please answer these questions with as much detail as possible.</div>
          <mat-horizontal-stepper disableRipple="true" linear #stepper [selectedIndex]="currentIndex - 1" (selectionChange)="setIndex($event, stepper)">
            <ng-template matStepperIcon="edit" let-index="index">
              <span>{{ index + 1 }}</span>
            </ng-template>

            <mat-step *ngFor="let data of brandMessagingData; let i = index">
              <ng-template matStepLabel>{{ data.tab }}</ng-template>

              <div class="question-content" *ngIf="!showThankYou">
                <div class="brand-messaging-wrapper">
          
                  <div class="questions-wrapper">
                    <div class="question-block">

                      <div class="question-block-title">{{ data.question }}</div>
                      <div class="question-block-label">{{ data.label }}</div>
                      <mat-form-field appearance="outline">
                        <textarea matInput [placeholder]="data.placeholder" [(ngModel)]="data.answer"></textarea>
                      </mat-form-field>

                    </div>
                  </div>

                  <div class="actionBtn">
                    <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()"
                      (click)="currentIndex < maxIndex ? moveStepper(currentIndex + 1, stepper) : submitData()">
                      <ng-container *ngIf="currentIndex < maxIndex">Next</ng-container>
                      <ng-container *ngIf="currentIndex == maxIndex">Share your responses</ng-container>
                    </button>
                  </div>
                  
                </div>

              </div>

              <div class="thank-you" *ngIf="showThankYou">
                <span class="material-icons">check_circle</span>
                <div class="heading">Your responses have been accepted.</div>
                <div class="content">
                  Just thought of something you'd like to change?<br />
                  <a (click)="showForm()">Click here</a> to edit your response.
                </div>
              </div>
            
            </mat-step>

          </mat-horizontal-stepper>
        </div>

      </div>
    </mat-tab>

    <div class="matspinner" *ngIf="isLoading">
      <mat-spinner strokeWidth="5"></mat-spinner>
    </div>
  </mat-tab-group>
</div>